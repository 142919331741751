<template>
  <prime-dialog
    ref="dialogRef"
    v-bind="$attrs"
    :modal="true"
    :dismissable-mask="dismissableMask"
    :close-on-escape="closeOnEscape"
    :closable="closable"
    :pt="dialogClasses"
  >
    <template v-for="(_, slot) in $slots" #[slot]="scope">
      <slot :name="slot" v-bind="scope || {}" />
    </template>

    <template v-if="$slots.header || header" #header>
      <div class="de-dialog-header" :class="headerClass">
        <slot name="header" :props="{ class: dialogClasses.title }">
          <div :class="dialogClasses.title">{{ header }}</div>
        </slot>
      </div>
    </template>

    <template v-if="$slots.footer" #footer>
      <div :class="footerClass">
        <slot name="footer" />
      </div>
    </template>
  </prime-dialog>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { computed, useAttrs } from '#imports';
import type { PrimeDialog } from '#components';

const attrs = useAttrs();

const props = withDefaults(
  defineProps<{
    contentClass?: string;
    headerClass?: string;
    footerClass?: string;
    iconsClass?: string;
    header?: string;
    dismissableMask?: boolean;
    closeOnEscape?: boolean;
    closable?: boolean;
  }>(),
  {
    dismissableMask: true,
    closeOnEscape: true,
    closable: true,
  },
);

const dialogClasses = computed(() => {
  return {
    root: ['de-dialog', attrs.class],
    content: ['de-dialog-content', props.contentClass],
    title: 'de-dialog-title',
    icons: ['de-dialog-icons', props.iconsClass],
    closeButton: 'de-dialog-close-button',
    mask: 'de-dialog-mask',
    transition: {
      enterFromClass: 'de-dialog-enter-from',
      enterActiveClass: 'de-dialog-enter-active',
      leaveActiveClass: 'de-dialog-leave-active',
      leaveToClass: 'de-dialog-leave-to',
    },
  };
});

const dialogRef = ref<InstanceType<typeof PrimeDialog> | null>(null);
const close = () => {
  if (!dialogRef.value) return;

  dialogRef.value.close();
};

defineExpose({
  close,
});
</script>
